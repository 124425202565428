export interface Archer {
  name: string
  category: "Cadet" | "Junior" | "Senior 1" | "Senior 2" | "Senior 3"
}

export interface Team {
  weapon: "CL" | "CO"
  gender: "H" | "F"
  captain: string
  members: Archer[]
}

export interface Club {
  name: string
  department: number
  teams: Team[]
}
const data: Club[] = [
  {
    name: "CEP Tir à l'arc - Épernay",
    department: 51,
    teams: [
      {
        weapon: "CL",
        gender: "F",
        captain: "",
        members: [],
      },
      {
        weapon: "CL",
        gender: "H",
        captain: "",
        members: [],
      },
      {
        weapon: "CO",
        gender: "H",
        captain: "",
        members: [],
      },
      {
        weapon: "CO",
        gender: "F",
        captain: "",
        members: [],
      },
    ],
  },
  {
    name: "1ère Compagnie d'Arc d'Hayange",
    department: 57,
    teams: [
      {
        weapon: "CL",
        gender: "F",
        captain: "",
        members: [],
      },
      {
        weapon: "CL",
        gender: "H",
        captain: "",
        members: [],
      },
      {
        weapon: "CO",
        gender: "H",
        captain: "",
        members: [],
      },
    ],
  },
  {
    name: "Compagnie d'Archers de Herrlisheim",
    department: 67,
    teams: [
      {
        weapon: "CO",
        gender: "H",
        captain: "",
        members: [],
      },
    ],
  },
  {
    name: "Les Compagnons d'Arc de Lingolsheim",
    department: 67,
    teams: [
      {
        weapon: "CL",
        gender: "H",
        captain: "",
        members: [],
      },
    ],
  },
  {
    name: "Les Archers de la Suippe - Suippes",
    department: 51,
    teams: [
      {
        weapon: "CL",
        gender: "H",
        captain: "",
        members: [],
      },
    ],
  },
  {
    name: "Compagnie des Archers du Bollwerk - Mulhouse",
    department: 68,
    teams: [
      {
        weapon: "CL",
        gender: "F",
        captain: "",
        members: [],
      },
      {
        weapon: "CL",
        gender: "H",
        captain: "",
        members: [],
      },
    ],
  },
  {
    name: "Les Archers Troyens",
    department: 10,
    teams: [
      {
        weapon: "CL",
        gender: "H",
        captain: "",
        members: [],
      },
    ],
  },
  {
    name: "1ère Compagnie de Vandoeuvre",
    department: 54,
    teams: [
      {
        weapon: "CL",
        gender: "F",
        captain: "",
        members: [],
      },
      {
        weapon: "CL",
        gender: "H",
        captain: "",
        members: [],
      },
    ],
  },
]

export default data
