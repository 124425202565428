import React, { ReactNode } from "react"
import { Helmet } from "react-helmet"
import { Button } from "@rmwc/button"
import { Layout } from "../../components/layout"
import { VieSportiveNav } from "../../components/menus/VieSportiveNav"
import dreData, { Club } from "../../data/dre"

interface State {
  clubs: Club[]
}

export default class DRE extends React.Component<unknown, State> {
  constructor(props: unknown, context: unknown) {
    super(props, context)

    this.state = { clubs: dreData }
  }

  render(): ReactNode {
    const { clubs } = this.state

    return (
      <Layout>
        <Helmet title="Division Régionale Excellence" />
        <div className="container">
          <div className="row mt-3">
            <div className="secondary-col">
              <VieSportiveNav />
            </div>
            <div className="main-col">
              <div className="my-3">
                <h1>Division Régionale Excellence 2024</h1>

                <p>La division régionale excellence s&apos;inscrit dans la filière par équipes de clubs mise en place par la FFTA.</p>

                <p>
                  <b>C&apos;est la compétition permettant aux clubs du Grand Est de confronter leurs équipes d’archers.</b>
                </p>

                <p>
                  Les équipes de clubs (4 ou 3 archers) doivent participer aux 3 manches : 2x70 m (arc classique) ou 2x50 m (arc à
                  poulies) avec finales. Le 2x70 m (arc classique) ou 2x50 m (arc à poulies) avec ou sans finales, est la seule discipline de référence pour la DR
                  &quot;Excellence&quot;.
                </p>

                <p>
                  Le calcul des points pour le classement final se fera sur le modèle de celui de la D2 (v. règlements sportifs FFTA art. C.3.2).
                </p>

                <p>
                  L&apos;équipe qui se classe 1<sup>ère</sup> de la DR &quot;Excellence&quot; remporte le titre de champion régional, et accède à la
                  finale nationale des DR, ainsi que les x meilleures équipes de clubs au classement national des équipes de DR afin d'arriver à 24 équipes qualifiées par catégorie.
                </p>

                <p>
                  Un club sélectionné et participant à la finale nationale des DR s’engage à monter en D2 l’année suivante s'il termine dans les 8 premiers du classement final de la Finale Nationale des DR ou s’il est repêché au classement.
                  En cas de refus de monter en D2, l’équipe du club sera interdite de participer à la finale des DR pendant les deux années suivantes.
                </p>

                <div className="card-deck my-3">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Public concerné</h4>
                      <ul className="card-text">
                        <li>hommes et femmes</li>
                        <li>par équipes de clubs non mixtes</li>
                        <li>dès la catégorie U18 (ex-cadet)</li>
                        <li>arc classique ou arc à poulies</li>
                      </ul>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Tarif d&apos;engagement</h4>
                      <p className="card-text">
                        <b>105 €</b> par équipe pour les 3 manches
                        {/* <div>
                          <Button raised tag="a" href="https://www.helloasso.com/associations/comite-regional-de-tir-a-l-arc-du-grand-est/evenements/inscription-equipe-dr-grand-est-2024" target="_blank" rel="noopener noreferrer">
                            S'inscrire
                          </Button>
                        </div> */}
                        {/* <ul className="card-text">
                          <li>par chèque à l&apos;ordre du CRTA du Grand Est</li>
                          <li>via &nbsp;
                            <a href="https://www.helloasso.com/associations/comite-regional-de-tir-a-l-arc-du-grand-est/evenements/inscription-equipe-dr-grand-est-2024"
                              target="_blank" rel="noopener noreferrer">
                              HelloAsso
                            </a>
                          </li>
                        </ul> */}
                      </p>
                    </div>
                  </div>
                </div>

                <p>Avant chaque manche, les équipes engagées devront inscrire les membres qui les composeront auprès de l'organisateur.</p>

                <hr />
                <h2>Étapes 2024</h2>

                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <div>
                      <i>Volgelsheim</i> - <small>14 avril 2024</small>
                    </div>
                    <div className="text-right">
                      <Button raised tag="a" href="/vie-sportive/documents/DRE24-1 - Matchs.pdf" target="_blank" rel="noopener noreferrer">
                        Résultats
                      </Button>{" "}
                      <Button raised tag="a" href="/vie-sportive/mandats/2024059.pdf" target="_blank" rel="noopener noreferrer">
                        Mandat
                      </Button>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <div>
                      <i>Troyes</i> - <small>5 mai 2024</small>
                    </div>
                    <div className="text-right">
                      <Button raised tag="a" href="/vie-sportive/documents/DRE24-2 - Matchs.pdf" target="_blank" rel="noopener noreferrer">
                        Résultats
                      </Button>{" "}
                      <Button raised tag="a" className="text-right" href="/vie-sportive/mandats/2024021.pdf" target="_blank"
                        rel="noopener noreferrer">
                        Mandat
                      </Button>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <div>
                      <i>Thaon-les-Vosges</i> - <small>9 juin 2024</small>
                    </div>
                    <div className="text-right">
                      <Button raised rised tag="a" href="/vie-sportive/documents/DRE24-3 - Matchs.pdf" target="_blank" rel="noopener noreferrer">
                        Résultats
                      </Button>{" "}
                      <Button raised tag="a" className="text-right" href="/vie-sportive/mandats/2024035.pdf" target="_blank"
                        rel="noopener noreferrer">
                        Mandat
                      </Button>
                    </div>
                  </li>
                </ul>

                <hr />

                {/* <h2>Inscrits</h2>

                <div className="row">
                  {clubs.map(club => (
                    <>
                      {club.teams.map(team => (
                        <div className="col-lg-6 col-12 my-3">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title">
                                {club.name}{" "}
                                <small>
                                  <em>{club.department}</em>
                                </small>
                              </h5>
                              <p>
                                <b>{team.weapon === "CL" ? "Classique" : "Poulies"}</b> -{" "}
                                <em>{team.gender === "H" ? "Hommes" : "Femmes"}</em>
                                <br />
                                Capitaine : <em>{team.captain}</em>
                              </p>
                              <p>
                                Archers :
                                <ul>
                                  {team.members.map(member => (
                                    <li>
                                      {member.name} -{" "}
                                      <small>
                                        <em>{member.category}</em>
                                      </small>
                                    </li>
                                  ))}
                                </ul>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                </div>

                <hr /> */}

                <h2>Classement</h2>

                <div className="card-deck">
                  <div className="card">
                    <div className="card-body">
                      <a href="/vie-sportive/documents/classement-dre-2024.pdf" target="_blank" rel="noopener noreferrer">
                        <h4 className="card-title">Classement 2024</h4>
                      </a>
                      <h6 className="cardrow-subtitle mb-2 text-muted">Classement final - MàJ le 10 juin</h6>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <h2>Archives</h2>
              <div className="card-deck">
                <div className="card">
                  <div className="card-body">
                    <a href="/vie-sportive/documents/classement-dre-2023.pdf" target="_blank" rel="noopener noreferrer">
                      <h4 className="card-title">Classement 2023</h4>
                    </a>
                    <h6 className="cardrow-subtitle mb-2 text-muted">PDF du 3 juillet 2023</h6>
                  </div>
                  <div className="card-body">
                    <a href="/vie-sportive/documents/classement-dre-2022.pdf" target="_blank" rel="noopener noreferrer">
                      <h4 className="card-title">Classement 2022</h4>
                    </a>
                    <h6 className="cardrow-subtitle mb-2 text-muted">PDF du 13 juin 2022</h6>
                  </div>
                  <div className="card-body">
                    <a href="/vie-sportive/documents/classement-dre-2019.pdf" target="_blank" rel="noopener noreferrer">
                      <h4 className="card-title">Classement 2019</h4>
                    </a>
                    <h6 className="cardrow-subtitle mb-2 text-muted">PDF du 23 juin 2019</h6>
                  </div>
                  <div className="card-body">
                    <a href="/vie-sportive/documents/classement-dre-2018.pdf" target="_blank" rel="noopener noreferrer">
                      <h4 className="card-title">Classement 2018</h4>
                    </a>
                    <h6 className="cardrow-subtitle mb-2 text-muted">PDF du 4 juillet 2018</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout >
    )
  }
}
